import { EyeOutlined } from '@ant-design/icons';
import {
  Button, Space, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import momo from 'common/assets/images/momo.png';
import paypal from 'common/assets/images/paypal.png';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusOrderLabel } from 'common/components/StatusLabel';
import {
  exportOrdersService,
  getAllOrderService
} from 'common/services/extends/order';
import { OrderStatusType } from 'common/services/extends/order/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';
import roles from 'configs/roles';

export type TableDataTypes = {
  id: number
  transactionId: string
  promotionName: string,
  promotionGetTime: number,
  packageName: string,
  packagePrice: number,
  tax: number
  customerName: string
  customerEmail: string
  customerPhone: string
  usageTime: number
  status: OrderStatusType['value']
  dateBilling: string
  priceDiscount: number
  createdAt: string
  updatedAt: string
  totalPrice: string
  paymentTransactionId: string
  paymentMethod: string
  expiredDate: string
};

const OrderManagement: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const {
    advancedFilter
  } = useAppSelector((state) => state.system);
  const { roles: role } = useAppSelector((state) => state.auth);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(25);
  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  // const [sortType, setSortType] = useState<{
  //   field: 'promotionStart' | 'promotionEnd',
  //   type: string
  // }>();

  const queryKey = ['order-list', keyword, currentPage, currentView, selectedFilterList];

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getAllOrderService({
      ...returnParamsFilter,
      keyword,
      page: currentPage,
      limit: currentView,
      // sortType: sortType?.type,
      // sortBy: sortType?.field
    }),
    {
      keepPreviousData: true,
      enabled: !!currentPage
    }
  );

  const { mutate: exportMute, isLoading: isLoadingExport } = useMutation(
    ['export-orders'],
    () => exportOrdersService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      onSuccess: (res) => {
        if (res.link) {
          message.success(t('message.exportSuccess'));
          window.open(res.link, '_blank');
        }
      },
      onError: () => {
        message.error(t('message.exportError'));
      }
    }
  );

  /* Data */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      id: val.orderData.id,
      transactionId: val.orderData.code,
      tax: val.orderData.tax,
      customerName: val.orderData.customer.name,
      customerEmail: val.orderData.customer.email,
      customerPhone: val.orderData.customer.phone,
      usageTime: val.orderData.usageTime,
      status: val.orderData.status as OrderStatusType['value'],
      dateBilling: val.orderData.dateBilling,
      createdAt: val.orderData.createdAt,
      updatedAt: val.orderData.updatedAt,
      totalPrice: val.orderData.price.toString(),
      paymentTransactionId: val.orderData.paypalOrderId || val.orderData.momoTransactionId || '',
      paymentMethod: val.orderData.paymentMethod,
      priceDiscount: val.orderData.priceDiscount,
      expiredDate: val.orderData.expireDate || '',
      promotionName: val.orderData.promotionProgram.promotionProgramName || '',
      promotionGetTime: Number(val.orderData.promotionProgram.promotionProgramGetTime),
      packageName: val.orderData.package.name,
      packagePrice: val.orderData.package.price

    })) || []), [listRes]);

  console.log('🚀 ~ tableData:', tableData);

  const paymentMethodUI = (method: string) => {
    if (method === 'Paypal') {
      return paypal;
    }
    if (method === 'Momo') {
      return momo;
    }
    return '';
  };

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'Transaction ID',
      key: 'id',
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text style={{ display: 'inline-block', width: 'max-content' }}>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: 'Transaction ID',
      key: 'transactionId',
      align: 'center',
      fixed: 'left',
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text style={{ display: 'inline-block', width: 'max-content' }}>
          {data.transactionId}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.customerName?.localeCompare(b.customerName)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.customerName}
        </Typography.Text>
      ),
    },
    {
      title: t('system.email'),
      dataIndex: 'customerEmail',
      key: 'customerEmail',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.customerEmail?.localeCompare(b.customerEmail)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.customerEmail}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'customerPhone',
      key: 'customerPhone',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.customerPhone?.localeCompare(b.customerPhone)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.customerPhone}
        </Typography.Text>
      ),
    },
    {
      title: t('order.packageName'),
      dataIndex: 'packageName',
      key: 'packageName',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.packageName?.localeCompare(b.packageName)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.packageName}
        </Typography.Text>
      ),
    },
    {
      title: t('order.packagePrice'),
      dataIndex: 'packagePrice',
      key: 'packagePrice',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.packagePrice - b.packagePrice,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatNumberWithSeparators(data.packagePrice)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.usageTime'),
      dataIndex: 'usageTime',
      key: 'usageTime',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.usageTime - b.usageTime,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatNumberWithSeparators(data.usageTime)}
        </Typography.Text>
      ),
    },
    // {
    //   title: t('order.unitPrice'),
    //   dataIndex: 'unitPrice',
    //   key: 'unitPrice',
    //   width: 78,
    //   sorter: {
    //     compare: (a: TableDataTypes, b: TableDataTypes) => a.unitPrice - b.unitPrice,
    //   },
    //   sortDirections: ['descend', 'ascend'],
    //   render: (_: string, data: TableDataTypes) => (
    //     <Typography.Text
    //       onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
    //       style={{ color: '#4a4a4a', cursor: 'pointer' }}
    //     >
    //       {data.unitPrice}
    //     </Typography.Text>
    //   ),
    // },
    // {
    //   title: t('order.unitPriceDuration'),
    //   dataIndex: 'unitPriceDuration',
    //   key: 'unitPriceDuration',
    //   sorter: {
    //     compare:
    //       (a: TableDataTypes, b: TableDataTypes) => a.unitPriceDuration - b.unitPriceDuration,
    //   },
    //   sortDirections: ['descend', 'ascend'],
    //   render: (_: string, data: TableDataTypes) => (
    //     <Typography.Text
    //       onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
    //       style={{ color: '#4a4a4a', cursor: 'pointer' }}
    //     >
    //       {data.unitPriceDuration}
    //     </Typography.Text>
    //   ),
    // },
    {
      title: t('order.promotionName'),
      dataIndex: 'promotionName',
      key: 'promotionName',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.promotionName?.localeCompare(b.promotionName)
      },
      sortDirections: ['descend', 'ascend'],

      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.promotionName}
        </Typography.Text>
      ),
    },
    // {
    //   title: t('order.unitPrice'),
    //   dataIndex: 'unitPrice',
    //   key: 'unitPrice',
    //   width: 78,
    //   sorter: {
    //     compare: (a: TableDataTypes, b: TableDataTypes) => a.unitPrice - b.unitPrice,
    //   },
    //   sortDirections: ['descend', 'ascend'],
    //   render: (_: string, data: TableDataTypes) => (
    //     <Typography.Text
    //       onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
    //       style={{ color: '#4a4a4a', cursor: 'pointer' }}
    //     >
    //       {data.unitPrice}
    //     </Typography.Text>
    //   ),
    // },
    {
      title: t('order.promotionGetTime'),
      dataIndex: 'promotionGetTime',
      key: 'promotionGetTime',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.promotionGetTime - b.promotionGetTime,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatNumberWithSeparators(data.promotionGetTime)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.priceDiscount'),
      dataIndex: 'priceDiscount',
      key: 'priceDiscount',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.priceDiscount - b.priceDiscount,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatNumberWithSeparators(data.priceDiscount)}
        </Typography.Text>
      ),
    },
    // {
    //   title: t('order.promotionStart'),
    //   dataIndex: 'promotionStart',
    //   key: 'promotionStart',
    //   onHeaderCell: () => ({
    //     onClick: () => {
    //       setSortType({ field: 'promotionStart',
    // type: sortType?.type === 'asc' ? 'desc' : 'asc' });
    //     },
    //   }),
    //   sorter: {},
    //   render: (_name: string, data: TableDataTypes) => (
    //     <Typography.Text>
    //       {formatDateTime(data.promotionStart)}
    //     </Typography.Text>
    //   ),
    // },
    // {
    //   title: t('order.promotionEnd'),
    //   dataIndex: 'promotionEnd',
    //   key: 'promotionEnd',
    //   onHeaderCell: () => ({
    //     onClick: () => {
    //       setSortType({ field: 'promotionEnd',
    // type: sortType?.type === 'asc' ? 'desc' : 'asc' });
    //     },
    //   }),
    //   sorter: {},
    //   render: (_name: string, data: TableDataTypes) => (
    //     <Typography.Text>
    //       {formatDateTime(data.promotionEnd)}
    //     </Typography.Text>
    //   ),
    // },
    {
      title: t('order.tax'),
      dataIndex: 'tax',
      key: 'tax',
      width: 86,
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.tax - b.tax,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.tax}
        </Typography.Text>
      ),
    },
    {
      title: <span>{t('order.paymentMethod')}</span>,
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (_: string, data: TableDataTypes) => (paymentMethodUI(data.paymentMethod) ? (
        <img style={{ maxWidth: '150px' }} src={paymentMethodUI(data.paymentMethod)} alt="Method payment" width={80} />
      ) : null),
    },
    {
      title: <span>{t('order.paymentMethodTransactionId')}</span>,
      dataIndex: 'paymentTransactionId',
      key: 'paymentTransactionId',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.paymentTransactionId}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.status - b.status,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          <StatusOrderLabel status={data.status} />
        </Typography.Text>
      ),
    },
    {
      title: t('order.dateBilling'),
      dataIndex: 'dateBilling',
      key: 'dateBilling',
      sorter: {
        compare:
          (a: TableDataTypes, b: TableDataTypes) => a.dateBilling?.localeCompare(b.dateBilling)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {formatDateTime(data.dateBilling)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.expireDate'),
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.expiredDate);
          const bDate = new Date(b.expiredDate);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.expiredDate)}
        </Typography.Text>
      ),
    },
    {
      title: t('order.totalPrice'),
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.totalPrice?.localeCompare(b.totalPrice)
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.totalPrice}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: <span>{t('system.action')}</span>,
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => roleIndex && navigate(`${ROUTE_PATHS.ORDER_DETAIL}?id=${_data.id}`)}
          />
        </Space>
      ),
    },
  ];

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('order', advancedFilter),
    [advancedFilter]
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() }, { replace: true });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam },
    }, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, setSearchParams, pageParam, selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Render */
  return (
    <div className="p-orderManagement">
      <HeaderPage
        fixed
        title={t('sidebar.order')}
        rightHeader={(
          <Button
            loading={isLoadingExport}
            disabled={!role.includes(roles.ORDER_EXPORT) && !role.includes('*')}
            onClick={() => exportMute()}
          >
            {t('system.export')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          noCheckbox
          roles={{
            roleCreate: false,
            roleDelete: false,
            roleUpdate: false
          }}
          tableProps={{
            initShowColumns: ['id', 'transactionId', 'paymentTransactionId', 'customerName', 'customerEmail', 'customerPhone', 'promotionName', 'promotionGetTime', 'priceDiscount', 'packageName', 'packagePrice', 'unitPrice', 'totalPrice', 'tax', 'usageTime', 'status', 'paymentMethod', 'expiredDate', 'dateBilling', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listRes?.meta.total || 1,
            noBaseCol: true,
            filterFields,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter
          }}
        />
      </div>
    </div>
  );
};

export default OrderManagement;
