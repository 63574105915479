import { OrderStatusType } from 'common/services/extends/order/types';

export const languageList = [
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const samplePage = [...Array(10)].map((_, idx) => ({
  label: `Trang mẫu ${idx + 1}`,
  value: `${idx + 1}`,
}));

export const statusDummy = [
  {
    label: 'Bản nháp',
    value: 1,
  },
  {
    label: 'Gửi duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const contactStatusDummy = [
  {
    label: 'Mới',
    value: 1,
  },
  {
    label: 'Chờ duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const customerStatusDummy = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 7,
  },
];

export const orderStatusDummy: {
  label: string,
  value: OrderStatusType['value']
}[] = [
    {
      label: 'Pending',
      value: 1,
    },
    {
      label: 'Failed',
      value: 7,
    },
    {
      label: 'Paid',
      value: 13,
    },
  ];
