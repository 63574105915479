/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Card,
  Col,
  Row,
  Typography
} from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import HeaderPage from 'common/components/HeaderPage';
import ManagementInfo from 'common/components/ManagementInfo';
import { StatusOrderLabel } from 'common/components/StatusLabel';
import {
  getDetailOrderService
} from 'common/services/extends/order';
import { OrderStatusType } from 'common/services/extends/order/types';
import { formatDateTime, formatNumberWithSeparators } from 'common/utils/functions';
import { useSchema } from 'common/utils/schemas';

type MainDataTypes = {
  // promotionStart: string
  // promotionEnd: string
  tax: number
  customerName: string
  customerEmail: string
  customerPhone: string
  usageTime: string
  status: number
  dateBilling: string
  unitPrice: string
  priceDiscount: string
  totalPrice: string
  paymentTransactionId: string
  expireDate: string
  packageName: string
  promotionProgramName?: string
  promotionProgramDiscount?: string
  promotionProgramType?: string
  promotionProgramGetTime?: string
};

const EditOrder: React.FC<ActiveRoles> = () => {
  const { orderDataSchema } = useSchema();

  const mainMethod = useForm<MainDataTypes>({
    resolver: yupResolver(orderDataSchema),
    mode: 'onChange',
    defaultValues: {
      unitPrice: '0',
      tax: 0,
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      usageTime: '0',
      status: 0,
      dateBilling: '',
      priceDiscount: '0',
      totalPrice: '0',
      paymentTransactionId: '',
      expireDate: '',
      packageName: '',
      promotionProgramName: '',
      promotionProgramDiscount: '0',
      promotionProgramGetTime: '0',
      promotionProgramType: '',
    }
  });

  /* Selectors */

  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));

  /* Queries */
  const queryKeyDetail = ['getDetailOrder', idParam];
  const { data: detailStaticBlock } = useQuery(
    queryKeyDetail,
    () => {
      if (idParam) {
        return getDetailOrderService(idParam);
      }
      return undefined;
    }
  );

  const detectPromotionTypeLabel = (key: number) => {
    switch (key) {
      case 1:
        return t('promotion.buyXGetY');
      case 2:
        return t('promotion.xHoursRate');
      default:
        return t('promotion.discountCode');
    }
  };

  /* Effects */
  useEffect(() => {
    if (detailStaticBlock) {
      mainMethod.reset({
        unitPrice: formatNumberWithSeparators(detailStaticBlock.orderData.package.price),
        priceDiscount: formatNumberWithSeparators(detailStaticBlock.orderData.priceDiscount),
        tax: detailStaticBlock.orderData.tax,
        customerName: detailStaticBlock.orderData.customer.name,
        customerEmail: detailStaticBlock.orderData.customer.email,
        customerPhone: detailStaticBlock.orderData.customer.phone,
        usageTime: formatNumberWithSeparators(detailStaticBlock.orderData.usageTime),
        status: detailStaticBlock.orderData.status,
        dateBilling: formatDateTime(detailStaticBlock.orderData.dateBilling || ''),
        totalPrice: formatNumberWithSeparators(detailStaticBlock.orderData.price || 0),
        paymentTransactionId: detailStaticBlock.orderData.paypalOrderId || detailStaticBlock.orderData.momoTransactionId || '',
        promotionProgramName: detailStaticBlock.orderData.promotionProgram.promotionProgramName || '',
        promotionProgramDiscount:
          detailStaticBlock.orderData.promotionProgram.promotionProgramDiscount && detailStaticBlock.orderData.promotionProgram.promotionProgramDiscount > 0
            ? `${detailStaticBlock.orderData.promotionProgram.promotionProgramDiscount}%`
            : '0',
        promotionProgramType:
          detailStaticBlock.orderData.promotionProgram.promotionProgramType
            ? detectPromotionTypeLabel(detailStaticBlock.orderData.promotionProgram.promotionProgramType)
            : '',
        promotionProgramGetTime:
          formatNumberWithSeparators(detailStaticBlock.orderData.promotionProgram.promotionProgramGetTime || 0),
        packageName: detailStaticBlock.orderData.package.name,
      });
    } else {
      mainMethod.reset();
    }
  }, [detailStaticBlock, mainMethod]);
  /* Render */
  return (
    <div className="p-editStaticBlock">
      <HeaderPage
        fixed
        title={idParam ? t('order.editOrderTitle') : t('order.createOrderTitle')}
      />
      <div className="t-mainlayout_wrapper">
        <Row gutter={16}>
          <Col xxl={18} xl={16} lg={16}>
            <Card
              type="inner"
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Typography.Text strong>
                    {t('system.name')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="customerName"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    {t('system.email')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="customerEmail"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('system.phone')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="customerPhone"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('system.status')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="status"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <div style={{ display: 'inline-block' }}>

                        <StatusOrderLabel status={value as OrderStatusType['value']} />
                      </div>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('order.paymentMethodTransactionId')}
                      :
                    </Typography.Text>
                    <Controller
                      name="paymentTransactionId"
                      control={mainMethod.control}
                      render={({
                        field: { value },
                      }) => (
                        <span className="p-editPageTemplate_textWrapper">
                          <Typography.Text
                            className="u-mt-8"
                          >
                            {value}
                          </Typography.Text>
                        </span>
                      )}
                    />
                  </div>
                </Col>
                <Col span={12} className="u-mt-16">
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('order.unitPrice')}
                      {': '}
                    </Typography.Text>
                    <Controller
                      name="unitPrice"
                      control={mainMethod.control}
                      render={({
                        field: { value },
                      }) => (
                        <span className="p-editPageTemplate_textWrapper">
                          <Typography.Text
                            className="u-mt-8"
                          >
                            {value}
                          </Typography.Text>
                        </span>
                      )}
                    />
                  </div>
                </Col>
                {/* <Col span={12} className="u-mt-16">
                  <div className="p-editPageTemplate_input">
                    <Typography.Text strong>
                      {t('order.unitPriceDuration')}
                      {': '}
                    </Typography.Text>
                    <span className="p-editPageTemplate_textWrapper">
                      <Typography.Text
                        className="u-mt-8"
                      >
                        {mainMethod.getValues('unitPricePromotionDuration')
                          ? mainMethod.getValues('unitPricePromotionDuration')
                          : mainMethod.getValues('unitPriceDuration')}
                      </Typography.Text>
                    </span>
                  </div>
                </Col> */}
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionName')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="promotionProgramName"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionType')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="promotionProgramType"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>

                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionDiscount')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="promotionProgramDiscount"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionGetTime')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="promotionProgramGetTime"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.packageName')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="packageName"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.usageTime')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="usageTime"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.priceDiscount')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="priceDiscount"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.tax')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="tax"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.totalPrice')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="totalPrice"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>

                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.dateBilling')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="dateBilling"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.expireDate')}
                    {': '}
                  </Typography.Text>
                  <Controller
                    name="expireDate"
                    control={mainMethod.control}
                    render={({
                      field: { value },
                    }) => (
                      <span className="p-editPageTemplate_textWrapper">
                        <Typography.Text
                          className="u-mt-8"
                        >
                          {value}
                        </Typography.Text>
                      </span>
                    )}
                  />
                </Col>
              </Row>
              {/* <Row gutter={24}>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionStart')}
                    {': '}
                  </Typography.Text>
                  <span className="p-editPageTemplate_textWrapper">
                    <Typography.Text>
                      {formatDateTime(mainMethod.getValues('promotionStart'))}
                    </Typography.Text>
                  </span>
                </Col>
                <Col span={12} className="u-mt-16">
                  <Typography.Text strong>
                    {t('order.promotionEnd')}
                    {': '}
                  </Typography.Text>
                  <span className="p-editPageTemplate_textWrapper">
                    <Typography.Text>
                      {formatDateTime(mainMethod.getValues('promotionEnd'))}
                    </Typography.Text>
                  </span>
                </Col>
              </Row> */}
            </Card>
          </Col>
          <Col xxl={6} xl={8} lg={8}>
            <ManagementInfo
              createdDate={detailStaticBlock ? moment(detailStaticBlock?.orderData?.createdAt).fromNow() : ''}
              createdBy={detailStaticBlock?.orderData?.customer.name || ''}
              lastUpdated={detailStaticBlock ? moment(detailStaticBlock?.orderData?.updatedAt).fromNow() : ''}
              lastUpdatedBy={detailStaticBlock?.orderData?.customer.name || ''}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditOrder;
